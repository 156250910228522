import {Node} from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import {IVideoPlayerAdditionalContentViewProps} from './components/additional-content';
import {IVideoPlayerViewProps} from './hp-video-player';

/**
 * Render Additional Content.
 * @param additionalContent - Additional content view props.
 * @returns JSX Element.
 */
const renderAdditionalContent = (additionalContent: IVideoPlayerAdditionalContentViewProps) => {
    if (additionalContent.additionalContentItems) {
        return (
            <Node {...additionalContent.additionalContentNode}>
                <>
                    {additionalContent.additionalContentItems.heading}
                    <Node {...additionalContent.additionalContentItems.additionalContentItemContainer}>
                        {additionalContent.additionalContentItems.text}
                        <Node {...additionalContent.additionalContentItems.additionalContentItemLinks}>
                            {additionalContent.additionalContentItems.links}
                        </Node>
                    </Node>
                </>
            </Node>
        );
    }
    return null;
};

const VideoPlayerView: React.FC<IVideoPlayerViewProps> = props => {
	let TagName: string;
	let attrs: object = {...props.renderModuleAttributes(props)};

	if(props.videoLink) {
		TagName = 'a';
		attrs = Object.assign(attrs,{href: props.videoLink, "aria-label": props.videoAriaLabel });
	} else {
		TagName = 'div';
	}
	// @ts-ignore
	return (<TagName id={props.id} className='ms-video-player d-block' {...attrs}>
		{props.player}
		<Node {...props.detailsContainer} className={props.detailsContainer ? props.detailsContainer.className : ''}>
			{props.title} {props.text} {props.additionalContent && renderAdditionalContent(props.additionalContent)}
			<Node {...props.detailsContainerCta} className={props.detailsContainerCta ? props.detailsContainerCta.className : ''}>
				{props.links}
			</Node>
		</Node>
	</TagName>);
};

export default VideoPlayerView;
