
  // tslint:disable
  //import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
  //import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
  //import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';


    /**
    * EcoResProductConfigurationConnSelmer entity interface.
    */
    export interface IEcoResProductConfigurationConnSelmer {
    BrandId?: string;
    SeriesId?: string;
    LevelId?: string;
    IsError: boolean;
    message?: string[];
    }

    /**
    * DimensionGroupsConnSelmer entity interface.
    */
    export interface IDimensionGroupsConnSelmer {
    DimensionOne?: string;
    DimensionFour?: string;
    DimensionThree?: string;
    IsError: boolean;
    message?: string[];
    }

  /**
   * EcoResProductConfigurationConnSelmer entity class.
   */
  export class EcoResProductConfigurationConnSelmerExtensionClass implements IEcoResProductConfigurationConnSelmer {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public BrandId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SeriesId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LevelId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsError: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public message: string[];

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BrandId = odataObject.BrandId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SeriesId = odataObject.SeriesId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LevelId = odataObject.LevelId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsError = odataObject.IsError;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.message = undefined;
        if (odataObject.message) {
          this.message = [];
          for (var i = 0; i < odataObject.message.length; i++) {
              // eslint-disable-next-line security/detect-object-injection
            if (odataObject.message[i]) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                // eslint-disable-next-line security/detect-object-injection
            this.message[i] =
                // eslint-disable-next-line security/detect-object-injection
                odataObject.message[i];

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                // eslint-disable-next-line security/detect-object-injection
              this.message[i] = undefined;
            }
          }
        }

      }
  }

  /**
   * DimensionGroupsConnSelmer entity class.
   */
  export class DimensionGroupsConnSelmerExtensionClass implements IDimensionGroupsConnSelmer {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DimensionOne: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DimensionFour: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DimensionThree: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsError: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public message: string[];

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DimensionOne = odataObject.DimensionOne;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DimensionFour = odataObject.DimensionFour;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DimensionThree = odataObject.DimensionThree;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsError = odataObject.IsError;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.message = undefined;
        if (odataObject.message) {
          this.message = [];
          for (var i = 0; i < odataObject.message.length; i++) {
              // eslint-disable-next-line security/detect-object-injection
            if (odataObject.message[i]) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                // eslint-disable-next-line security/detect-object-injection
            this.message[i] =
                // eslint-disable-next-line security/detect-object-injection
                odataObject.message[i];

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                // eslint-disable-next-line security/detect-object-injection
              this.message[i] = undefined;
            }
          }
        }

      }
  }
